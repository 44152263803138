<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Tous les groupes</div>

            <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="/addgroup">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter un groupe
                  </li>
                </router-link>
              </ol>
            </nav>

            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par nom"
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Membre</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(group, key) in searchFunction" :key="key">
                    <td>
                      {{ group.name }}
                    </td>


                    <td>
                        <i
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
                          data-toggle="modal"
                          :data-target="'#test' + group._id"
                        >
                        </i>

                        <!-- Button trigger modal -->

                        <!-- Modal -->

                        

                        <div
                          class="modal fade"
                          :id="'test' + group._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5>{{ group.user_id["name"] }}</h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Les membres du groupe</h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>nom</th>
                                      <th>telephone</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        users, index
                                      ) in group.user_id"
                                      :key="index"
                                    >
                                      <td>
                                        {{ group.user_id["name"] }}
                                      </td>
                                      <td>{{ group.user_id.telephone }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </td>
                    <!-- <td> -->
                      <!-- <li v-for="(x, key1) in group.user_id" :key="key1">
                        {{ [x] }}
                      </li> -->
                    <!-- </td> -->
                    <td>
                      <router-link
                        :to="{
                          name: 'updategroups',
                          params: { id: group._id },
                        }"
                        ><i
                          class="mdi mdi-pen icone text-success"
                          style="color: green"
                        ></i> </router-link
                      >&nbsp;
                      <i
                        class="mdi mdi-delete icone text-danger"
                        style="color: red"
                        @click="deletegroup(group._id)"
                      />
                    </td>

                   
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  data() {
    return {
      groups: [],
      search: "",
      
    };
  },

  computed: {
    searchFunction() {
      return this.groups.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },

  created() {
    this.getgroups();
  },
  methods: {
    getgroups() {
      HTTP.get("groupes/getgroups")
        .then((response) => {
          this.groups = response.data;
        })
        .then((err) => console.log(err));
    },

    deletegroup(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé l'groupe",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("groupes/deletegroup/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "groupe supprimé",
            });

            this.getgroups();
          });
        }
      });
    },
  },
};
</script>
